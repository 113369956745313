<template>
  <section>
    <van-nav-bar title="订单查询" left-arrow @click-left="backSpace" />
    <div v-for="(item, index) in dataList" :key="index" class="module">
      <div class="module_item">订单编号：{{ item.order_no }}</div>
      <!-- <div class="module_item">商品名：{{ item.title }}</div> -->
      <!-- <div class="module_item">原价：{{ item.price }}</div> -->
      <div class="module_item">下单时间：{{ item.create_time }}</div>
      <div class="module_item">发票抬头：{{ item.code }}</div>
      <div class="module_item">税号：{{ item.num }}</div>
      <div v-if="item.status == 3 || item.status == 4" class="module_item">
        快递公司名称：{{ item.k_name }}
      </div>
      <div v-if="item.status == 3 || item.status == 4" class="module_item">
        快递单号：{{ item.k_num }}
      </div>
      <div class="module_item">
        状态：
        <span v-if="item.status == 1">待支付</span>
        <span v-if="item.status == 2">待发货</span>
        <span v-if="item.status == 3">已发货</span>
        <span v-if="item.status == 4">已完成</span>
      </div>
      <div class="module_item">
        支付类型：
        <span v-if="item.payment_type == 1">微信支付</span>
        <span v-if="item.payment_type == 2">微信支付</span>
        <span v-if="item.payment_type == 3">支付宝支付</span>
        <span v-if="item.payment_type == 4">支付宝支付</span>
        <span v-if="item.payment_type == 5">余额支付</span>
        <span v-if="item.payment_type == 6">对公转账</span>
      </div>
      <!-- <div class="module_item fx">
        <div>截图：</div>
        <div class="f1 fx wp">
          <img
            v-for="(itemS, indexS) in item.thumb"
            :key="indexS"
            @click="imageClick(itemS)"
            class="module_pic"
            :src="itemS"
            alt=""
          />
        </div>
      </div> -->
      <div>
        <div class="list">
          <div class="fx">
            <div class="f1 faj">型号</div>
            <div class="f1 faj">单价</div>
            <div class="f1 faj">数量</div>
          </div>
          <div v-for="(itemS, indexS) in item.sku" class="list_item" :key="indexS">
            <div class="fx">
              <div class="f1 faj">{{ itemS.title }}</div>
              <div class="f1 faj">{{ itemS.price }}</div>
              <div class="f1 faj">{{ itemS.num }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="module_item">实付金额：{{ item.pay }}</div>
    </div>
    <van-dialog v-model="alert" title="图片详情" show-cancel-button>
      <img class="alert_pic" :src="image" />
    </van-dialog>
  </section>
</template>
<script>
import Vue from "vue";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
import { payGetList } from "@/assets/api/api";
import { Dialog } from "vant";
// 全局注册
Vue.use(Dialog);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      dataList: "",
      alert: false,
      image: "",
    };
  },
  computed: {},
  methods: {
    imageClick(e) {
      this.alert = true;
      this.image = e;
    },
    payGetList() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      payGetList().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          this.dataList = res.data.data.data;
        } else {
          Toast(res.data.message);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.payGetList();
  },
};
</script>
<style scoped>
.module {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}
.module_item {
  padding: 5px 0;
  font-size: 14px;
  color: #333;
}
.module_pic {
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 0 5px 5px;
}
.list {
  padding: 10px 0;
  font-size: 14px;
}
.list_item{
  margin-top: 15px;
}
.alert_pic {
  display: block;
  width: 100%;
}
</style>
